import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <ul>
      <li>
        <Link to="/joint-sounds-signs-of-things-to-come/">
          <h2>Joint Sounds: Signs of Things to Come?</h2>
        </Link>
        <p>“Snap, crackle, pop!” No, that was not milk being poured over Rice Krispies. It’s your knees when you get up from the couch or your shoulders...</p>
      </li>
      <li>
        <Link to="/healthy-kids-make-healthy-adults/">
          <h2>Healthy Kids Make Healthy Adults</h2>
        </Link>
        <p>Do kids get enough exercise these days? It’s very likely that kids today are not getting enough exercise. With the rapid growth and popularity of technology...</p>
      </li>
      <li>
        <Link to="/starting-a-new-exercise-program/">
          <h2>Starting a New Exercise Program?</h2>
        </Link>
        <p>When you begin the journey to better health, adding exercise is an important part. Just remember exercise comes in many shapes and forms. There are low...</p>
      </li>
      <li>
        <Link to="/page-2/">
          <h2>Page 2 defined by Gatsby</h2>
        </Link>
        <p>All posts are imported into this site at the time of build. This page is defined in Gatsby...</p>
      </li>
    </ul>

  </Layout>
)


export default IndexPage
